import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as WheelbarrowEmpty } from '../icons/wheelbarrow-empty.svg'
import { ReactComponent as WheelbarrowFilled } from '../icons/wheelbarrow-filled.svg'
import { useEffect, useState } from 'react'
import { formatPrice } from '../functions'
import { removeFromCart } from '../redux/cart'
import Button from './Button'
import { Link } from '../lib/Router'

function Cart() {
    const [open, setOpen] = useState(false)
    const cart = useSelector(state => state.cart)
    const objects = useSelector(state => state.objects.objects)
    const dispatch = useDispatch()

    const hasObjects = cart.objectIds.length > 0
    const cartObjects = objects.filter(object => cart.objectIds.indexOf(object.id) >= 0)

    useEffect(() => {
        function onClickOutside(e) {
            if (!e.target.closest('.TopbarCart')) {
                setOpen(false)
            }
        }
        window.addEventListener('click', onClickOutside)

        return () => window.removeEventListener('click', onClickOutside)
    }, [])

    function onClick(e) {
        e.preventDefault()
        if (!hasObjects) return
        setOpen(old => !old)
    }

    function removeItem(id) {
        dispatch(removeFromCart(id))
    }

    return <div className={`TopbarCart${open && hasObjects ? ' open' : ''}`}>
        <div className='label' onClick={onClick}>
            {hasObjects ? <WheelbarrowFilled/> : <WheelbarrowEmpty/>}
            {hasObjects ? <span className="Badge">{cart.objectIds.length}</span> : null}
        </div>
        <div className="panel">
            {cartObjects.map(object => (
                <div key={object.id} className="item">
                    {object.name} - {formatPrice(object.price)}
                    <Button variant='basic' onClick={e => { e.preventDefault(); e.stopPropagation(); removeItem(object.id) }}>Verwijderen</Button>
                </div>
            ))}
            <div key='actions' className='actions'>
                <Link to='/sponsor/afrekenen' component={Button} variant='primary'>Sponsor deze {cartObjects.length === 1 ? 'boom' : 'bomen'}</Link>
            </div>
        </div>
    </div>
}

export default Cart
