import { useRef, useState } from "react"
import Button from './Button'

function FileInput({ id, label, initialImage, onChange }) {
    const ref = useRef()
    const [file, setFile] = useState(null)
    const [keepInitial, setKeepInitial] = useState(true)

    function onFileChange(e) {
        const newFile = e.target.files.length > 0 ? e.target.files[0] : null
        setFile(newFile)
        onChange && onChange(newFile)
    }

    function onRemove(e) {
        ref.current.value = null
        setFile(null)
        onChange && onChange(keepInitial ? 'keep' : null)
    }

    function onRemoveInitial(e) {
        e.preventDefault()
        setKeepInitial(false)
    }

    return <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <div className="Field file" style={{ flexGrow: 1, marginBottom: 0 }}>
            <span className="display">{file?.name||(keepInitial&&initialImage)}</span>
            <input ref={ref} type="file" className={`${file||(keepInitial&&initialImage) ? 'filled' : ''}`} id={id} onChange={onFileChange} />
            <label htmlFor={id}>{label}</label>
            <button className="CloseButton" style={{ display: file ? null : 'none'}} onClick={onRemove}>&times;</button>
        </div>
        {initialImage && keepInitial && <Button inline onClick={onRemoveInitial}>Verwijderen</Button>}
    </div>
}

export default FileInput
