import { useState } from "react"
import auth from "../lib/auth"
import { Link } from "../lib/Router"
import Button from "./Button"
import Menu from "./Menu"
import Cart from "./Cart"

function Topbar() {
  const [user, setUser] = useState(auth.session?.user)

  async function logout(e) {
    e.preventDefault()
    await auth.logout()
    setUser(null)
  }

  return <div className="Topbar">
      <div className="TopSpacer"></div>
    </div>
}

export default Topbar


// <button className="Button secondary" style={{ marginBottom: 0, marginLeft: 10, marginRight: 0 }} onClick={login}>Inloggen</button>
