import Button from "../components/Button"
import Header from "../components/Header"
import Topbar from "../components/Topbar"
import { Link } from "../lib/Router"

function Home() {
    return <>
        <Topbar/>
        <div className="Container page">
            <h1>Voedselbos De Pelgrim</h1>
            <div className="HomeColumns">
                <div className="HomeText">
                    <p>Welkom bij VoedselBos & BoerderHeij De Pelgrim</p>

                    <p>
                        Wij dromen van een heerlijk, vruchtbaar VoedselBos waar mens en dier geniet
                        van alles wat er te zien, te beleven en te proeven is. Een plek waar je tot
                        rust komt en ontdekkingen doet. Een bos waar kinderen en volwassenen leren
                        over biologisch voedsel. Waar komende generaties ook nog van oogsten. Een
                        stukje grond waar de biodiversiteit enorm is toegenomen en weer soorten
                        voorkomen waarvan we dachten dat ze verdwenen waren uit de omgeving. Kortom
                        een bos vol smaken, geuren en kleuren.
                    </p>
                    <p>
                        Wij hebben in juni 2022 een boerderijtje met 1,2 hectare grond gevonden in
                        het Oost-Groningse Bellingwolde, vlak bij de Duitse grens, waar we bezig
                        zijn deze droom te realiseren.
                    </p>
                    <p>
                        Op dit moment zitten we nog helemaal in de beginfase van het bos. Vorig jaar
                        hebben we de hulpbomen, de pioniers geplant. Zij helpen de bodem te verbeteren
                        en bieden straks onder andere beschutting tegen de elementen.
                    </p>
                    <p>
                        Dit najaar staat de aanplant van de notenbomen en de fruitstruiken en -bomen op de planning.
                    </p>
                    <p>
                        Het zou super tof zijn als jij deze droom helpt waarmaken; een plekje waar het leven goed is voor mens en dier.
                    </p>

                    <p>
                        <Link to="/sponsor" component={Button}>Sponsor een boom</Link>
                    </p>
                </div>
                <div className="HomeMedia">
                    <figure>
                        <img src="/photo1.jpg" alt="Voedselbos in aanbouw"/>
                    </figure>
                    <figure>
                        <img src="/photo2.jpg" alt="Anita en Dorine"/>
                    </figure>
                </div>
            </div>
        </div>
    </>
}

export default Home
