import auth from "../lib/auth"
import { useDispatch, useSelector } from "react-redux"
import { addToCart } from "../redux/cart"
import { formatPrice } from "../functions"
import config from "../config"
import { setMessage } from "../redux/message"

function ObjectInfo({ id, onBackToHome, onEdit }) {
  const object = useSelector(state => state.objects.objects?.find(object => object.id === id))
  const dispatch = useDispatch()

  function onHomeLinkClick(e) {
    e.preventDefault()
    if (onBackToHome) onBackToHome()
  }

  function onSponsorClick(e) {
    e.preventDefault()
    dispatch(addToCart(id))
    dispatch(setMessage({ message: 'De boom is aan je kruiwagentje toegevoegd' }))
  }

  function onEditClick(e) {
    e.preventDefault()
    if (onEdit) onEdit(id)
  }

  if (!object) {
    return <div className="ObjectInfo">
      <h2>Niet gevonden</h2>
    </div>
  }

  return <>
    <button className="CloseButton" onClick={onHomeLinkClick}>&times;</button>
    <div className='ModalHeader'>
      <h2>{object.name}</h2>
    </div>
    <div className="ModalContent">
      {object.image &&
        <img src={`${config.api.url}/previews/crop/640x480/${object.image}`} alt={object.original_image_name} style={{ float: 'right', width: 320 }} />
      }

      {object.latin_name && <h3 className="object.latin_name" style={{ marginTop: 0 }}>{object.latin_name}</h3>}


      {object.description &&
        <p>
          {object.description}
        </p>
      }

      {object.status === 'available' && parseFloat(object.price)
        ? <>
            <h3>Sponsor deze boom voor {formatPrice(object.price)}</h3>
            <p>
              Door deze boom te sponsoren doneer je aan het
              voedselbos om deze boom te kunnen planten.
            </p>

            <p>
              <button className="Button" onClick={onSponsorClick}>
                Sponsor deze boom
              </button>
            </p>
          </>
        : (object.status === 'ordered' ? 'Deze boom is al gesponsord' : `Deze boom is (nu) niet te sponsoren`)
      }

      {auth.session?.user ? <p>
        <button className="Button secondary" onClick={onEditClick}>
          Aanpassen
        </button>
      </p> : null}
    </div>
  </>
}

export default ObjectInfo
