import { useRef, useState } from 'react'
import Map from '../components/Map'
import MapItem from '../components/MapItem'
import ObjectInfo from "../components/ObjectInfo"
import ObjectInfoEdit from "../components/ObjectInfoEdit"
import Topbar from "../components/Topbar"
import { useSelector } from 'react-redux'
import Header from '../components/Header'
import SponsorInfo from '../components/SponsorInfo'

function offset(el) {
  let _el = el
  const off = { top: 0, left: 0 }
  while (_el) {
    off.top += _el.offsetTop
    off.left += _el.offsetLeft
    _el = _el.offsetParent
  }
  return off
}

function Sponsor() {
  const [selectedId, setSelectedId] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const ref = useRef()
  let mapBox = null

  const objects = useSelector(state => state.objects.objects)
  const objectsStatus = useSelector(state => state.objects.status)

  function onMapClick(e) {
    if (editMode) return;

    const els = document.elementsFromPoint(e.pageX - window.scrollX, e.pageY - window.scrollY)

    let newActive = null
    let backupEl = null
    const options = []

    if (!mapBox && ref) {
      mapBox = ref.current.querySelector('.Map')
    }

    for (let i = 0; i < els.length; i++) {
      const el = els[i]
      if (!el.classList.contains('MapItem')) continue
      const index = el.getAttribute('dataindex');

      if (objects[index].id == '335') {
        backupEl = el
        continue
      }

      const off = offset(el)
      const dx = e.pageX - (off.left + el.offsetWidth / 2)
      const dy = e.pageY - (off.top + el.offsetHeight / 2)
      // const dy = (e.pageY + mapBox.scrollTop) - (mapBox.offsetTop + el.offsetTop + el.offsetHeight / 2)
      const radius = Math.max(el.offsetWidth, el.offsetHeight) / 2
      const dist = Math.sqrt(dx * dx + dy * dy)

      if (!newActive && dist <= radius) {
        newActive = el
        break
      } else {
        options.push({ value: objects[index].id, label: objects[index].name })
      }
    }

    if (!newActive) newActive = backupEl

    const active = document.querySelector(".MapItem.active")

    if (newActive) {
      if (newActive !== active) {
        const index = newActive.getAttribute("dataindex")
        setSelectedId(objects[index].id)
      }
    } else if (active) {
      setSelectedId(null)
    }
  }

  function onBackToHome() {
    const active = document.querySelector(".MapItem.active")
    active?.classList.remove("active")
    setSelectedId(null)
  }

  function onEditObject() {
    setEditMode(true)
  }

  function onCancelEdit() {
    setEditMode(false)
  }

  return <>
    <Topbar/>
    <div ref={ref} className='SponsorColumns'>
      <SponsorInfo/>
      {objectsStatus !== 'succeeded'
        ? <Map/>
        : <>
            <Map onClick={onMapClick}>
              {objects?.map((object, index) => (
                <MapItem
                  key={index}
                  x={object.data.x}
                  y={object.data.y}
                  size={object.data.size}
                  label={object.name}
                  index={index}
                  status={object.status}
                  svgPath={object.data.path}
                  active={object.id === selectedId}
                />
              ))}
            </Map>

            {selectedId && <div className='ModalContainer'>
              <div className='Modal'>
                {editMode
                  ? <ObjectInfoEdit
                      id={selectedId}
                      onCancel={onCancelEdit}
                    />
                  : <ObjectInfo
                      id={selectedId}
                      onBackToHome={onBackToHome}
                      onEdit={onEditObject}
                    />
                }
              </div>
            </div>}

            {/* <InfoPanel active={!!selectedId}>
              {selectedId
                ? (editMode
                    ?
                      <ObjectInfoEdit
                        id={selectedId}
                        onCancel={onCancelEdit}
                      />
                    : <ObjectInfo
                        id={selectedId}
                        onBackToHome={onBackToHome}
                        onEdit={onEditObject}
                      />
                  )
                : <GeneralInfo/>
              }
            </InfoPanel> */}
          </>
      }
    </div>
  </>
}

export default Sponsor
