import { useContext, useEffect } from "react"
import { Link, RouteContext } from "../lib/Router"
import api from "../lib/api"
import Topbar from "../components/Topbar"
import { useQuery } from "react-query"
import Button from "../components/Button"
import Header from "../components/Header"
import { useDispatch } from "react-redux"
import { clearCart } from "../redux/cart"

function Payment() {
    const context = useContext(RouteContext)

    const { data, isLoading } = useQuery({ queryKey: ['payment', context.id], retry: false, queryFn: async () => {
        const response = await api.payment.process({ id: context.id })
        return response.data
    }})

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(clearCart())
    }, [ data?.status, dispatch ])

    if (isLoading || !data) {
        return <>
            <Topbar/>
            <div className="Container page">
                <p>Even geduld, je betaling wordt gecontroleerd...</p>
            </div>
        </>
    }

    if (!data.success) {
        console.error(data.error || 'Unknown error')

        return <>
            <Topbar/>
            <div className="Container page">
                <h2>Helaas, je betaling kon niet worden gecontroleerd</h2>
                <p>Weet je zeker dat je wel hebt betaald? Neem dan even contact met ons op, dan komen we er vast uit.</p>
                <Link to='https://voedselbosdepelgrim.nl/' component={Button}>Terug naar home</Link>
            </div>
        </>
    }

    if (data.status === 'canceled' || data.status === 'expired' || data.status === 'failed') {
        return <>
            <Topbar/>
            <div className="Container page">
                <h2>Helaas, je betaling is niet gelukt</h2>
                <p>Probeer het nog een keer</p>
                <Link to='https://voedselbosdepelgrim.nl/' component={Button}>Terug naar home</Link>
            </div>
        </>
    }

    return <>
        <Topbar/>
        <Header>
            <h2>Bedankt voor je sponsoring!</h2>
        </Header>
        <div className="Container page">
            {data.status === 'paid'
                ? <p>Je betaling is gelukt. Gefeliciteerd! Je bent nu de trotse sponsor van een van onze bomen!</p>
                : <p>Je betaling wordt op dit moment verwerkt. Zodra het gelukt is ontvang je daarover een mail.</p>
            }

            <p>
                Krijg je binnen enkele minuten geen mail? Check dan even je spam map. Daar ook geen mail? Neem dan even contact met ons op.
            </p>

            <Link to='https://voedselbosdepelgrim.nl/' component={Button}>Terug naar home</Link>
        </div>
    </>
}

export default Payment
