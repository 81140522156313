import './App.css'
import { QueryClientProvider, QueryClient } from 'react-query'
import Sponsor from './pages/Sponsor'
import Login from './pages/Login'
import { Literal, Regex, RouterContext, useRouter } from './lib/Router'
import DoReset from './pages/DoReset'
import Reset from './pages/Reset'
import Payment from './pages/Payment'
import AboutUs from './pages/AboutUs'
import Home from './pages/Home'
import Checkout from './pages/Checkout'
import { useDispatch, useSelector } from 'react-redux'
import { fetchObjects } from './redux/objects'
import { useEffect, useState } from 'react'
import { setMessage } from './redux/message'

const queryClient = new QueryClient()

function App() {
  const [messageVisible, setMessageVisible] = useState(false)

  const router = useRouter()

  const objectsStatus = useSelector(state => state.objects.status)
  const message = useSelector(state => state.message)
  const dispatch = useDispatch()

  useEffect(() => {
    if (objectsStatus === 'idle') {
      dispatch(fetchObjects())
    }
  }, [objectsStatus, dispatch])

  useEffect(() => {
    if (message?.message) {
      setMessageVisible(true)
      setTimeout(() => {
        setMessageVisible(false)
        setTimeout(() => dispatch(setMessage({})), 500);
      }, 5000);
    }
  }, [message, dispatch])

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RouterContext.Provider value={router}>
          <Literal path="/"><Home/></Literal>
          <Literal path="/sponsor"><Sponsor/></Literal>
          <Literal path="/sponsor/afrekenen"><Checkout/></Literal>
          <Literal path="/over-ons"><AboutUs/></Literal>
          <Regex regex={/^\/login\/reset\/([^/]+)\/([^/]+)/} params={{ username: 1, challenge: 2 }}><DoReset/></Regex>
          <Literal path="/login/reset"><Reset/></Literal>
          <Literal path="/login"><Login/></Literal>
          <Regex regex={/\/payment\/(\d+)/} params={{id: 1}}><Payment/></Regex>

          {message?.message && <div className={`Toast${messageVisible ? ' visible' : ''}`}>{message.message}</div>}
        </RouterContext.Provider>
      </QueryClientProvider>
    </div>
  )
}

export default App
