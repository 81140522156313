import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    objectIds: JSON.parse(localStorage.getItem('pelgrim-cart') || '[]')
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, action) {
            const objectId = action.payload
            if (state.objectIds.indexOf(objectId) < 0) {
                state.objectIds.push(objectId)
                localStorage.setItem('pelgrim-cart', JSON.stringify(state.objectIds))
            }
        },
        removeFromCart(state, action) {
            const objectId = action.payload
            state.objectIds = state.objectIds.filter(oid => oid !== objectId)
            localStorage.setItem('pelgrim-cart', JSON.stringify(state.objectIds))
        },
        clearCart(state) {
            state.objectIds = []
            localStorage.setItem('pelgrim-cart', JSON.stringify(state.objectIds))
        }
    }
})

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions

export default cartSlice.reducer
