import Header from "../components/Header"
import Topbar from "../components/Topbar"

function AboutUs() {
    return <>
        <Topbar/>
        <Header>
            <h2>Over ons!</h2>
        </Header>
        <div className="Container page">
            <p>
                In juni 2022 zijn wij, Anita Bos en Dorine Heij, verhuisd van Amersfoort naar Bellingwolde.
                Daar konden we een boerderijtje kopen met 1,2 ha land erbij. Op een halve hectare gaan we een voedselbos aanleggen.
                In het najaar van 2022 zijn we al gestart met het planten van pioniersbomen.
                In het najaar van 2023 willen we de fruit- en notenbomen en -struiken gaan planten.
            </p>

            <h3>Onze droom</h3>
            <p>
                Wij dromen van een heerlijk, vruchtbaar VoedselBos waar mens en dier geniet van alles wat er te zien, te beleven en te proeven is.
                Een plek waar je tot rust komt en ontdekkingen doet. Een bos waar kinderen en volwassenen leren over biologisch voedsel.
                Waar komende generaties ook nog van oogsten. Een stukje grond waar de biodiversiteit enorm is toegenomen
                en weer soorten voorkomen waarvan we dachten dat ze verdwenen waren uit de omgeving. Kortom een bos vol smaken, geuren en kleuren.
            </p>

            <h3>Even wat zakelijke dingen</h3>
            <p>
                We vinden het heel tof als je ons sponsort. Dat kun je doen door een bedrag te betalen via iDeal.
                Per boom en struik noemen we een bedrag. Door dit te doen geef je een gift aan onze stichting.
                Mocht je, nadat je de gift hebt gegeven, je toch bedenken; mail ons dan en we zullen het bedrag terugstorten.
                Hiervoor heb je 14 dagen de tijd.
            </p>
            <p>Het geld dat je doneert gebruiken we om fruit- en notenbomen en -struiken van te kopen.</p>
            <p>We gebruiken je gegevens nooit voor iets anders dan onze administratie, en zullen je gegevens nooit doorgeven aan een derde partij.</p>

            <h3>Contactgegevens</h3>
            <p>Stichting De Pelgrim VoedselBos & BoerderHeij<br/>
            1e Bovenstreeklaan 1<br/>
            9695 BN<br/>
            Bellingwolde<br/>
            voedselbosdepelgrim@gmail.com<br/>
            KVK 88223159</p>

            <p>Je kunt ons ook volgen op Instagram en Facebook</p>
        </div>
    </>
}

export default AboutUs
