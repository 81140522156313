import config from '../config'

const auth = {
  session: null,
  isExpired: function() {
    return this.session?.expires?.getTime() < Date.now()
  },
  login: async function(email, password) {
    let response = await fetch(config.api.url + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      })
    })
    const json = await response.json()

    if (!json.access_token) {
      throw new Error("Invalid credentials")
    }

    response = await fetch(config.api.url + '/auth/status', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${json.access_token}`
      }
    })
    const data = await response.json()

    this.session = {
      accessToken: json.access_token,
      refreshToken: json.refresh_token,
      expires: new Date(json.expires),
      user: data.user
    }

    localStorage.setItem('auth', JSON.stringify({ ...this.session, expires: json.expires }))
  },
  logout: async function() {
    if (!this.session?.accessToken) return
    await fetch(config.api.url + '/auth/logout', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.session.accessToken}`
      }
    })
    this.session = null
    localStorage.removeItem('auth')
  },
  refresh: async function() {
    const response = await fetch(config.api.url + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: auth.session.refreshToken
      })
    })
    const json = await response.json()
    if (!json.access_token) {
      throw new Error("Unable to refresh token")
    }
    this.session.accessToken = json.access_token
    if (json.refresh_token) {
      this.session.refreshToken = json.refresh_token
    }
    this.session.expires = new Date(json.expires)
  },
  initReset: async function(username) {
    await fetch(config.api.url + '/auth/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username
      })
    })
  },
  checkResetChallenge: async function(username, challenge) {
    const response = await fetch(config.api.url + '/auth/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        challenge
      })
    })
    const json = await response.json()
    return json
  },
  reset: async function(username, challenge, password, passwordConfirm) {
    const response = await fetch(config.api.url + '/auth/reset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        challenge,
        password,
        password_confirm: passwordConfirm
      })
    })
    const json = await response.json()
    return json
  }
}

const stored = localStorage.getItem('auth')
if (stored) {
  auth.session = JSON.parse(stored)
  auth.session.expires = new Date(auth.session.expires)
}

export default auth
