import { useRef } from "react"

function Textarea({ id, className, value, onChange }) {
    const areaRef = useRef()
    const shadowRef = useRef()

    function onTAChange(e) {
        shadowRef.current.innerText = e.target.value + ' '
        areaRef.current.style.height = `${shadowRef.current.clientHeight}px`
        onChange && onChange(e.target.value)
    }

    return <div id={id} className={`Textarea ${className}`}>
        <textarea value={value} onChange={onTAChange} ref={areaRef} />
        <div className="shadow" ref={shadowRef}></div>
    </div>
}

export default Textarea
