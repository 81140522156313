import { useContext, useState } from "react"
import auth from "../lib/auth"
import { Link, RouterContext } from "../lib/Router"

function Login() {
  const [hasError, setHasError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const router = useContext(RouterContext)

  async function login() {
    try {
      await auth.login(email, password)
      setHasError(false)
      router.navigate('/')
    } catch(ex) {
      console.error(ex)
      setHasError(true)
    }
  }

  return <div className="Fullscreen">
    <div className="Box">
      <div className="BoxContent">
        <h1>Inloggen</h1>

        {hasError ? <div className="Alert error">
          De ingevulde gegevens kloppen niet
        </div> : null}

        <form className="Form" method="post">
          <div className="Field text">
            <input type="text" id="login_email" className={email ? 'filled' : ''} value={email} onChange={e => setEmail(e.target.value)} />
            <label htmlFor="login_email">E-mailadres</label>
          </div>
          <div className="Field text">
            <input type="password" id="login_password" className={password ? 'filled' : ''} value={password} onChange={e => setPassword(e.target.value)} />
            <label htmlFor="login_password">Wachtwoord</label>
          </div>
          <div className="Display text">
            <Link to="/login/reset">Wachtwoord vergeten?</Link>
          </div>
          <div className="Buttons">
            <button type="button" className="Button" onClick={login}>Log in</button>
          </div>
        </form>
      </div>
    </div>
  </div>
}

export default Login
