import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message: null,
    type: null
}

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setMessage(state, action) {
            const msg = action.payload
            state.message = msg?.message || null
            state.type = msg?.type || null
        },
        clearMessage(state) {
            state.message = null
            state.type = null
        }
    }
})

export const { setMessage, clearMessage } = messageSlice.actions

export default messageSlice.reducer
