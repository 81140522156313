function Button({ variant, inline, caption, onClick, children }) {
    return <button
        className={`Button ${variant}`}
        style={inline ? { marginBottom: 0, marginLeft: 10, marginRight: 0 } : null}
        onClick={onClick}>
            {caption || children}
        </button>
}

export default Button
