import { useContext, useState } from "react"
import auth from "../lib/auth"
import { Link, RouteContext, RouterContext } from "../lib/Router"
import { useQuery } from "react-query"

function DoReset() {
  const [hasError, setHasError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const router = useContext(RouterContext)
  const context = useContext(RouteContext)

  const query = useQuery([ 'passreset', context.challenge ], () => {
    return auth.checkResetChallenge(context.username, context.challenge)
  }, { refetchOnWindowFocus: false })

  async function reset() {
    if (!password || !passwordConfirm) return

    try {
      await auth.reset(context.username, context.challenge, password, passwordConfirm)
      setHasError(false)
      router.navigate('/')
    } catch(ex) {
      setHasError(true)
    }
  }

  return <div className="Fullscreen">
    <div className="Box">
      <div className="BoxContent">
        <h1>Wachtwoord herstellen</h1>

        {hasError
          ? <div className="Alert error">De wachtwoorden komen niet overeen</div>
          : null}

        {!hasError && query.isLoading
          ? <p>Even geduld a.u.b.</p>
          : (!hasError && query.data?.success
              ? <form className="Form" method="post">
                  <div className="Field text">
                    <input type="password" id="login_password" className={password ? 'filled' : ''} value={password} onChange={e => setPassword(e.target.value)} />
                    <label htmlFor="login_password">Wachtwoord</label>
                  </div>
                  <div className="Field text">
                    <input type="password" id="login_password_confirm" className={passwordConfirm ? 'filled' : ''} value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} />
                    <label htmlFor="login_password_confirm">Herhaal wachtwoord</label>
                  </div>
                  <div className="Display text">
                    <Link to="/login">Terug naar inloggen</Link>
                  </div>
                  <div className="Buttons">
                    <button type="button" className="Button" onClick={reset}>Opslaan</button>
                  </div>
                </form>
              : <div className="Alert error">Deze link is niet (meer) geldig</div>)}
      </div>
    </div>
  </div>
}

export default DoReset
