import { useState } from "react"
import auth from "../lib/auth"
import { Link } from "../lib/Router"

function Reset() {
  const [isSent, setIsSent] = useState(false)
  const [username, setUsername] = useState('')

  async function sendReset() {
    if (!username) return
    try {
      await auth.initReset(username)
    } catch(ex) {
    }
    setIsSent(true)
  }

  return <div className="Fullscreen">
    <div className="Box">
      <div className="BoxContent">
        <h1>Wacvhtwoord herstellen</h1>

        {isSent
          ? <div className="Alert success">
              Als je e-mailadres bekend is, sturen we daar een link naar toe om je wachtwoord opnieuw in te stellen.
            </div>
          : <form className="Form" method="post">
              <div className="Display text">
                Vul je e-mailadres in om verder te gaan
              </div>
              <div className="Field text">
                <input type="text" id="login_username" className={username ? 'filled' : ''} value={username} onChange={e => setUsername(e.target.value)} />
                <label htmlFor="login_username">E-mailadres</label>
              </div>
              <div className="Display text">
                <Link to="/login">Terug naar aanmelden</Link>
              </div>
              <div className="Buttons">
                <button type="button" className="Button" onClick={sendReset}>Volgende</button>
              </div>
            </form>
          }

      </div>
    </div>
  </div>
}

export default Reset
