import { createContext, useContext, useState } from "react"

const RouterContext = createContext({})
const RouteContext = createContext({})

function useRouter() {
  const [path,setPath] = useState(window.location.pathname || '/')

  function navigate(path) {
    window.history.pushState({}, '', path)
    setPath(path)
  }

  return { path, setPath, navigate }
}

function Literal({ path, children }) {
  const rContext = useContext(RouterContext)
  const context = useContext(RouteContext)
  const locationPath = context.path || rContext.path || '/'

  if (locationPath !== path) {
    return null
  }

  return children
}

function Part({ path, children }) {
  const rContext = useContext(RouterContext)
  const context = useContext(RouteContext)
  const locationPath = context.path || rContext.path || '/'

  if (!locationPath.startsWith(path)) {
    return null
  }

  return <RouteContext.Provider value={{ path: locationPath.substring(path.length) || '/' }}>
    {children}
  </RouteContext.Provider>
}

function Regex({ regex, params, children }) {
  const rContext = useContext(RouterContext)
  const context = useContext(RouteContext)
  const locationPath = context.path || rContext.path || '/'

  const match = locationPath.match(regex)
  if (!match) {
    return null
  }

  const value = {}

  if (params) {
    for (let key in params) {
      value[key] = match[params[key]]
    }
  }

  return <RouteContext.Provider value={value}>
    {children}
  </RouteContext.Provider>
}

function Link({ to, component, children, ...props }) {
  const rContext = useContext(RouterContext)
  const Component = component

  function onClick(e) {
    e.preventDefault()
    e.stopPropagation()
    if (to.match(/^[^:]+:\/\//)) {
      window.top.location = to
      return
    }
    rContext.navigate(to)
  }

  return Component
    ? <Component onClick={onClick} {...props}>{children}</Component>
    : <a href={to} onClick={onClick} {...props}>{children}</a>
}

export {
  useRouter,
  Literal,
  Part,
  Link,
  Regex,
  RouterContext,
  RouteContext,
}
