import { configureStore } from '@reduxjs/toolkit'
import objectsReducer from './objects'
import cartReducer from './cart'
import messageReducer from './message'

export default configureStore({
    reducer: {
        objects: objectsReducer,
        cart: cartReducer,
        message: messageReducer,
    }
})
