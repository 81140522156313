const priceFormat = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })
const priceFormatPlain = new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

function formatPrice(price) {
    return priceFormat.format(parseFloat(price||0))
}

function formatPricePlain(price) {
    return priceFormatPlain.format(parseFloat(price||0))
}

export { formatPrice, formatPricePlain }
