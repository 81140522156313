import { useState } from "react"
import auth from "../lib/auth"
import { Link } from "../lib/Router"
import Button from "./Button"
import Cart from './Cart'

function SponsorInfo() {
    const [user, setUser] = useState(auth.session?.user)

    async function logout(e) {
        e.preventDefault()
        await auth.logout()
        setUser(null)
    }

    return <div className="SponsorInfo">
        <div className="Topmost">
            <div className="Container">
                <div className="left">
                    {user
                    ? <>
                        Ingelogd als {user?.name}&nbsp;
                        <Button variant='basic' inline onClick={logout}>Uitloggen</Button>
                        </>
                    : <Link to="/login" component={Button} inline variant='basic'>Inloggen</Link>
                    }
                </div>
                <div className="right">
                    <Cart/>
                </div>
            </div>
        </div>
        <p>
            Je kunt bij ons een boom of struik sponsoren. Daarmee help je tal
            van vogels, honderden bijen, duizenden insecten, miljoenen wormen
            en miljarden bodemdieren. Waarschijnlijk zelfs veel meer, want
            sommige bomen die we nu planten kunnen wel 200 jaar oud worden! Je
            helpt mee om een stukje Nederland een gezonde en fijne plek te
            laten zijn voor mens en dier. Een boom sponsoren werkt zo: je
            kiest een boom of struik uit die wij geplant hebben in het
            VoedselBos, doneert het bedrag voor die boom en dan heb je hem
            gesponsord. Je kunt meerdere bomen of struiken in één keer
            sponsoren. Je stopt ze dan in de kruiwagen (het winkelwagentje).
        </p>
        <p>
            Sponsor je tot een bedrag van € 20 (hiervoor heb je bijvoorbeeld
            een Krulmoerbei) dan zijn we gewoon super blij met je bijdrage!
        </p>
        <p>
            Sponsor je één of meerder bomen of struiken voor een bedrag boven
            de &euro; 20 (hiervoor sponsor je bijvoorbeeld 6 Erwtenstruikjes)
            dan verbinden we jouw naam (als je dit wilt) letterlijk aan de
            boom/struik die je sponsort. Als je meerdere bomen voor dit bedrag
            sponsort verbinden we hem aan één van de bomen.
        </p>
    </div>
}

export default SponsorInfo
