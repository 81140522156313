import { useEffect, useState } from "react"
import Textarea from "./Textarea"
import { useDispatch, useSelector } from "react-redux"
import { updateObject } from "../redux/objects"
import { formatPricePlain } from "../functions"
import FileInput from "./FileInput"

const objectOptions = [
  { title: "Aardbeiboom", subtitle: "Arbutus unedo", price: "0,00" },
  { title: "Amandel", subtitle: "Prunus dulcis", price: "25,50" },
  { title: "Appel (juli, augustus)", subtitle: "Malus domestica", price: "0,00" },
  { title: "Appel (oktober, november)", subtitle: "Malus domestica", price: "0,00" },
  { title: "Appel (september)", subtitle: "Malus domestica", price: "0,00" },
  { title: "Appelbes", subtitle: "Aronia melanocarpa", price: "4,75" },
  { title: "Appelbes", subtitle: "Aronia prunifolia", price: "4,99" },

  { title: "Bergthee", subtitle: "Gaultheria shallon", price: "0,00" },
  { title: "Berken", subtitle: "Betula nigra, betula pendula", price: "0,00" },
  { title: "Bijenboom", subtitle: "Tetrdium danielii", price: "0,00" },
  { title: "Bosbes", subtitle: "Vaccinium myrtillus", price: "0,00" },

  { title: "Chinese kwee", subtitle: "Chaenomeles cathayensis", price: "21,75" },
  { title: "Chinese mahonie", subtitle: "Toona sinensis", price: "22,00" },

  { title: "Duindoorm, man", subtitle: "Hippophae rhamnoides", price: "0,00" },
  { title: "Duindoorm, vrouw", subtitle: "Hippophae rhamnoides", price: "0,00" },

  { title: "Erwtenstruikje", subtitle: "Caragana arborescens", price: "3,59" },

  { title: "Fazantbes, Caramelbes", subtitle: "Leycesteria formosa", price: "0,00" },
  { title: "Fluweelboom", subtitle: "Rhus glabra", price: "17,06" },

  { title: "Gele kornoelje", subtitle: "Cornus mas", price: "18,99" },
  { title: "Gewone vlier", subtitle: "Sambucus nigra", price: "2,56" },
  { title: "Grootvrucht meidoorn", subtitle: "Crataegus pinnatifada", price: "0,00" },

  { title: "Hazelaar", subtitle: "Corylus avellana", price: "10,00" },
  { title: "Herfstolijfwilg", subtitle: "Elaeagnus umbellata", price: "4,75" },
  { title: "Hibiscus", subtitle: "Hibiscus syriacus", price: "0,00" },
  { title: "Hican (15-25)", subtitle: "Carya cordiformis x illinoinensis", price: "53,00" },
  { title: "Honingbes", subtitle: "Lonicera caerulea (juli)", price: "6" },
  { title: "Honingboom", subtitle: "Sophora Japonica", price: "0,00" },

  { title: "Japanse kwee", subtitle: "Chaenomeles speciosa/japonica", price: "10,00" },
  { title: "Japanse walnoot", subtitle: "Juglans ailantifolia", price: "35,00" },
  { title: "Japanse wijnbes", subtitle: "Rubus phoenicolasius", price: "8,50" },
  { title: "Jostabes", subtitle: "Ribes josta", price: "0,00" },

  { title: "Keukenlaurier", subtitle: "Laurus nobilis", price: "0,00" },
  { title: "Krentenboompje", subtitle: "Amelanchier alnifolia spp", price: "6,50" },
  { title: "Kruisbes", subtitle: "Ribes uva-crispa", price: "4,50" },
  { title: "Kruisingen frambozen", subtitle: "Rubus hibrids", price: "5,25" },

  { title: "Langstelige olijfwilg", subtitle: "Elaeagnus multiflora", price: "0,00" },
  { title: "Lijsterbes", subtitle: "Sorbus aucuparia", price: "12,50" },
  { title: "Lijsterbes (struiken)", subtitle: "Sorbus spp", price: "0,00" },

  { title: "Mahonie, Druifstruik", subtitle: "Mahonia aquifolium", price: "0,00" },
  { title: "Mexicaanse meidoorn", subtitle: "Crataegus tanacetifolia", price: "0,00" },
  { title: "Mispel", subtitle: "Mespilus germanica", price: "21,50" },

  { title: "Nashi peer", subtitle: "Pyrus pyrifolia (midden)", price: "14,50" },
  { title: "Nashi peer", subtitle: "Pyrus pyrifolia (vroeg)", price: "0,00" },

  { title: "Peerlijsterbes", subtitle: "Sorbopyrus auriculata", price: "18,5" },
  { title: "Peer (hand)", subtitle: "Pyrus communis", price: "0,00" },
  { title: "Peer (stoof)", subtitle: "Pyrus communis", price: "0,00" },
  { title: "Pruim (vroeg)", subtitle: "Prunus domestica", price: "21,00" },

  { title: "Pawpaw", subtitle: "Asimina triloba", price: "32,50" },
  { title: "Pindakaasboom", subtitle: "Clerodendrum trichotomum", price: "0,00" },
  { title: "Pimpernoot, Blaasnoot", subtitle: "Staphylea spp", price: "9,00" },

  { title: "Rimpelroos", subtitle: "Rosa rugosa", price: "0,00" },
  { title: "Rode bes", subtitle: "Ribes rubrum", price: "7,25" },
  { title: "Rozemarijn", subtitle: "Rosmarinus officinalis", price: "0,00" },

  { title: "Schapenbes", subtitle: "Viburnum Lentago", price: "8,00" },
  { title: "Sechuan peper", subtitle: "Zanthoxylum", price: "0,00" },
  { title: "Suikeresdoorn", subtitle: "Acer saccharum", price: "0,00" },

  { title: "Tamme kastanje", subtitle: "Castanea sativa", price: "27,50" },
  { title: "Theehortensia", subtitle: "Hydrangea serrata", price: "0,00" },
  { title: "Theeviburnum", subtitle: "Viburnum setigerum", price: "0,00" },
  { title: "Turkse hazelaar", subtitle: "Corylus colurna", price: "43,00" },

  { title: "Valse indigo", subtitle: "Amorpha fruticosa", price: "0,00" },
  { title: "Vossebes", subtitle: "Vaccinium vitis-idaea", price: "0,00" },

  { title: "Walnoot (15-20)", subtitle: "Juglans regia", price: "55,00" },
  { title: "Wilde gagel", subtitle: "Myrica gale", price: "4,5" },

  { title: "Zoete kers", subtitle: "Prunus avium", price: "21,00" },
  { title: "Zoethout", subtitle: "Glycyrrhiza glabra", price: "0,00" },
  { title: "Zomerlinde", subtitle: "Tilia platyphillos", price: "2,26" },
  { title: "Zwarte bes", subtitle: "Ribes nigrum", price: "7,25" },
  { title: "Zwarte moerbei", subtitle: "", price: "0,00" },
]

function ObjectInfoEdit({ id, onCancel }) {
  const object = useSelector(state => state.objects.objects?.find(object => object.id === id))
  const dispatch = useDispatch()

  const [titleValue, setTitleValue] = useState(object.name || '')
  const [subtitleValue, setSubtitleValue] = useState(object.latin_name || '')
  const [descriptionValue, setDescriptionValue] = useState(object.description || '')
  const [priceValue, setPriceValue] = useState(formatPricePlain(object.price || '0.00'))
  const [availableValue, setAvailableValue] = useState(object.status === 'available')
  const [imageSelected, setImageSelected] = useState(null)
  const [fillerValue, setFillerValue] = useState(-1)

  useEffect(() => {
    setTitleValue(object.name || '')
    setSubtitleValue(object.latin_name || '')
    setDescriptionValue(object.description || '')
    setPriceValue(formatPricePlain(object.price || '0.00'))
    setFillerValue(-1)
  }, [ object ])

  function onFillerChange(e) {
    const index = e.target.selectedIndex - 1
    setFillerValue(index)
    if (index < 0 || index >= objectOptions.length) {
      setTitleValue(object?.name || '')
      setSubtitleValue(object?.latin_name || '')
      setPriceValue(formatPricePlain(object?.price || '0.00'))
    } else {
      const option = objectOptions[index]
      setTitleValue(option.title)
      setSubtitleValue(option.subtitle)
      setPriceValue(formatPricePlain(option.price))
    }
  }

  function onCancelClick(e) {
    e.preventDefault()
    if (onCancel) onCancel()
  }

  function onSaveClick(e) {
    e.preventDefault()
    const body = new FormData()
    body.set('id', id)
    body.set('name', titleValue)
    body.set('latin_name', subtitleValue)
    body.set('description', descriptionValue)
    body.set('price', parseFloat((priceValue || '0').replace(',', '.')))
    body.set('image', imageSelected)
    if (object.status !== 'ordered') {
      body.set('status', availableValue ? 'available' : 'unavailable')
    }
    dispatch(updateObject(body))
    onCancel && onCancel()
  }

  return <>
    <div className="ModalHeader">
      <h2>{object.name}</h2>
    </div>
    <div className="ModalContent">
      <div className="ObjectInfoEdit Form">
        <div className="Field">
          <div className="Select">
            <label htmlFor="object_filler">Selecteer preset:</label>
            <span className="value-label">{fillerValue >= 0 ? `${objectOptions[fillerValue].title} (${objectOptions[fillerValue].subtitle}), €${objectOptions[fillerValue].price}` : '(geen)'}</span>
            <select name="filler" id="object_filler" defaultValue={fillerValue} onChange={onFillerChange}>
              <option key={0}>(geen)</option>
              {objectOptions.map((option, index) => (
                <option key={index}>{option.title} ({option.subtitle}), €{option.price}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="Field text">
          <input className={`${titleValue ? 'filled' : ''}`} id="object_title" type="text" value={titleValue} onChange={(e) => setTitleValue(e.target.value)} />
          <label htmlFor="object_title">Naam</label>
        </div>
        <div className="Field text">
          <input className={`${subtitleValue ? 'filled' : ''}`} id="object_subtitle" type="text" value={subtitleValue} onChange={(e) => setSubtitleValue(e.target.value)} />
          <label htmlFor="object_subtitle">Latijnse naam</label>
        </div>
        <div className="Field text">
          <Textarea className={`${descriptionValue ? 'filled' : ''}`} id="object_description" value={descriptionValue} onChange={(v) => setDescriptionValue(v)} />
          <label htmlFor="object_description">Omschrijving</label>
        </div>
        <FileInput id="object_image" label="Afbeelding" initialImage={object.original_image_name} onChange={file => setImageSelected(file)}/>

        <div className="Field text">
          <input className={`${priceValue ? 'filled' : ''}`} id="object_price" type="text" value={priceValue} onChange={(e) => setPriceValue(e.target.value)} placeholder="Klik hier om een prijs op te geven"/>
          <label htmlFor="object_price">Prijs</label>
        </div>

        <div className="Field checkbox">
          <input id="object_available" disabled={object.status === 'ordered'} type="checkbox" checked={availableValue} onChange={(e) => setAvailableValue(e.target.checked)}/>
          <label htmlFor="object_available">Beschikbaar</label>
          {object.status === 'ordered' ? <div className='description'>Deze boom is al gesponsord</div> : ''}
        </div>

        <p>
          <button className="Button" onClick={onSaveClick}>
            Opslaan
          </button>
          <button className="Button secondary" onClick={onCancelClick}>
            Annuleren
          </button>
        </p>
      </div>
    </div>
  </>
}

export default ObjectInfoEdit
