function Map({ onClick, children }) {
  return <div
    className="Map"
    onClick={onClick}
  >
    <div className="MapInner">
      {children}
    </div>
  </div>
}

export default Map
