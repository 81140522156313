import auth from "../lib/auth"

const MAP_WIDTH = 1284
const MAP_HEIGHT = 3100

function MapItem({ x, y, size, label, index, active, status, svgPath, onClick }) {
  return <div
    className={`MapItem svg ${status}${auth.session?.user && label.match(/^\d+$/) ? ' without-label' : ''}${active ? ' active' : ''}`}
    style={{
      left: `${100 * x / MAP_WIDTH}%`,
      top: `${100 * y / MAP_HEIGHT}%`,
      width: `${100 * size[0] / MAP_WIDTH}%`,
      height: `${100 * size[1] / MAP_HEIGHT}%`
    }}
    onClick={onClick}
    dataindex={index}
  >
    {svgPath
      ? <svg viewBox={`0 0 ${size[0]} ${size[1]}`}>
          <path d={svgPath} fill="none" stroke="red"/>
          {/* <text x={size[0] / 2} y={(size[1] + 9) / 2} textAnchor="middle">{label}</text> */}
        </svg>
      : <svg viewBox={`0 0 ${size[0]} ${size[1]}`}>
          <ellipse d={svgPath} cx={size[0] / 2} cy={size[1] / 2} rx={size[0] / 2 - 2} ry={size[1] / 2 - 2} fill="none" stroke="red"/>
          {/* <text x={size[0] / 2} y={(size[1] + 9) / 2} textAnchor="middle">{label}</text> */}
        </svg>
    }
    {/* <div className="Tooltip">{label}</div> */}
  </div>
}

export default MapItem
