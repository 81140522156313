import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../lib/api'

const initialState = {
    status: 'idle',
    error: null,
    objects: []
}

export const fetchObjects = createAsyncThunk('objects/fetchObjects', async () => {
    const response = await api.objects.index()
    return response.data.objects
})

export const updateObject = createAsyncThunk('objects/updateObject', async initialObject => {
    const id = initialObject.id||initialObject.get('id')
    const response = await api.objects.update(id, initialObject)
    if (response.data.success) {
        const res = await api.objects.read(id)
        return res.data.object
    } else {
        return {}
    }
})

const objectsSlice = createSlice({
    name: 'objects',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchObjects.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchObjects.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.objects = action.payload
            })
            .addCase(fetchObjects.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateObject.fulfilled, (state, action) => {
                console.log(action)
                const body = action.payload
                const id = body.id || body.get('id')

                const existingObject = state.objects.find(object => object.id === id)
                if (existingObject) {
                    if (body instanceof FormData) {
                        existingObject.name = body.get('name')
                        existingObject.latin_name = body.get('latin_name')
                        existingObject.description = body.get('description')
                        existingObject.price = body.get('price')
                    } else {
                        const { name, latin_name, description, price } = body
                        existingObject.name = name
                        existingObject.latin_name = latin_name
                        existingObject.description = description
                        existingObject.price = price
                    }
                }
            })
    }
})

// export const { updateObject } = objectsSlice.actions

export default objectsSlice.reducer
