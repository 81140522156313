import config from '../config'
import auth from './auth'
import axios from 'axios'

const baseUri = config.api.url

const authAxios = axios.create()
authAxios.interceptors.request.use(async config => {
  if (auth.isExpired()) {
    try {
      await auth.refresh()
    } catch (ex) {
      console.error(ex)
      auth.logout()
      config.cancel = true
    }
  }

  if (auth.session?.accessToken) {
    config.headers = config.headers || {}
    config.headers.Authorization = `Bearer ${auth.session.accessToken}`
  }

  return config
})

const api = {
  objects: {
    index: () => axios.get(`${baseUri}/objects`),
    create: (body) => authAxios.post(`${baseUri}/objects`, body),
    read: (id) => authAxios.get(`${baseUri}/objects/${id}`),
    update: (id, body) => authAxios.post(`${baseUri}/objects/${id}`, body),
    delete: (id) => authAxios.delete(`${baseUri}/objects/${id}`),
  },
  orders: {
    create: (body) => axios.post(`${baseUri}/orders`, body)
  },
  payment: {
    methods: () => axios.get(`${baseUri}/payment/methods`),
    start: (body) => axios.post(`${baseUri}/payment/start`, body),
    process: (body) => axios.post(`${baseUri}/payment/process`, body),
  }
}

export default api
