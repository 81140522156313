import { useDispatch, useSelector } from "react-redux"
import Topbar from "../components/Topbar"
import { formatPrice } from "../functions"
import { useState } from "react"
import api from "../lib/api"
import Header from "../components/Header"
import Button from "../components/Button"
import { removeFromCart } from "../redux/cart"

function Checkout() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [sending, setSending] = useState(false)

    const cart = useSelector(state => state.cart)
    const objects = useSelector(state => state.objects.objects)
    const dispatch = useDispatch()

    const hasObjects = cart.objectIds.length > 0
    const cartObjects = objects.filter(object => cart.objectIds.indexOf(object.id) >= 0)
    const cartTotal = cartObjects.reduce((total, object) => total + parseFloat(object.price), 0)

    async function onSubmit(e) {
        e.preventDefault()
        setSending(true)

        const order = await api.orders.create({ name, email, objectIds: cart.objectIds })
        if (!order.data.success) {
            if (order.data.error === 'unavailable') {
                alert(`Helaas, de volgende bomen zijn niet meer beschikbaar:\n- ${order.data.unavailable.join("\n- ")}`);
            } else {
                alert('Oeps, er is iets misgegaan')
                console.log('order', order.data)
            }
            setSending(false)
            return
        }

        const payment = await api.payment.start({ orderId: order.data.id, method: 'ideal' })
        if (!payment.data.success) {
            alert('Oeps, er is iets misgegaan')
            console.log('payment', payment.data.error)
            setSending(false)
            return
        }

        window.top.location = payment.data.redirectUrl
    }

    function onEmailChange(e) {
        const value = e.target.value
        setEmail(value)
        setValidEmail(value && value.match(/^[^\s@]+@([^.\s@]+\.)+[a-z]{2,}$/))
    }

    function removeItem(id) {
        dispatch(removeFromCart(id))
    }

    if (!hasObjects) {
        return <>
            <Topbar/>
            <Header>
                <h2>Afrekenen</h2>
            </Header>
            <div className="Container page">
                <p>Je hebt nog niks in je kruiwagentje</p>
            </div>
        </>
    }

    return <>
        <Topbar/>
        <Header>
            <h2>Afrekenen</h2>
        </Header>
        <div className="Container page">
            <div style={{ maxWidth: 600 }}>
                <p>
                    Super dat je een of meerdere bomen wil sponsoren! Vul hieronder je naam en e-mailadres in en klik op 'Betalen'.
                    Je wordt dan doorgestuurd naar een betaalpagina waar je via iDEAL kunt betalen.
                </p>
                <p>
                    Na het betalen krijg je van ons een bevestiging.
                </p>
                <p>
                    Je gegevens worden alleen gebruikt voor administratieve doeleinden.
                </p>

                <h3>Je kruiwagentje</h3>

                <table className="Table">
                    <tbody>
                        {cartObjects.map(object => (
                            <tr key={object.id}>
                                <td>{object.name}<br/><em>{object.latin_name}</em></td>
                                <td><Button variant='basic' onClick={e => { e.preventDefault(); e.stopPropagation(); removeItem(object.id) }}>Verwijderen</Button></td>
                                <td className='align-right'>{formatPrice(object.price)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td><strong>Totaal</strong></td>
                            <td></td>
                            <td className='align-right'><strong>{formatPrice(cartTotal)}</strong></td>
                        </tr>
                    </tfoot>
                </table>

                <h3>Sponsor deze {cartObjects.length === 1 ? 'boom' : 'bomen'} voor {formatPrice(cartTotal)}</h3>

                <form className="Form">
                    <div className="Field text">
                        <input className={`${name ? 'filled' : ''}`} id="order_name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                        <label htmlFor="order_name">Naam</label>
                    </div>
                    <div className="Field text">
                        <input className={`${email ? 'filled' : ''}`} id="order_email" type="email" value={email} onChange={onEmailChange} />
                        <label htmlFor="order_email">E-mailadres</label>
                    </div>

                    <p>
                        <button className="Button" onClick={onSubmit} disabled={sending || !name || !validEmail}>
                            Betalen
                        </button>
                    </p>
                </form>
            </div>
        </div>
    </>
}

export default Checkout
