import { Link, useRouter } from "../lib/Router"

function MenuItem({ url, label }) {
    const router = useRouter()
    return <Link to={url} className={`MenuItem${router.path === url ? ' active' : ''}`}>{label}</Link>
}

function Menu() {

    return <div className="Menu">
        <MenuItem label='Voedselbos De Pelgrim' url='/'/>
        <MenuItem label='Sponsor een boom' url='/sponsor'/>
        <MenuItem label='Over ons' url='/over-ons'/>
    </div>
}

export default Menu
